import type { UseQueryResult } from '@tanstack/react-query';
import type { EmailSearchResult } from '../../../email-search/model';
import { useEmailSearch } from '../../../email-search/service/email-search-service';
import type {
  DeviceSearchResult,
  SearchObject,
} from '../../model/search.model';
import { useDeviceDetailsSearch } from './device-service';

export type SearchResult =
  | ({ type: 'device' } & UseQueryResult<DeviceSearchResult>)
  | ({ type: 'email' } & UseQueryResult<EmailSearchResult>);

export function useDeviceSearch(
  search: SearchObject | undefined,
): SearchResult {
  const deviceSearchResult = useDeviceDetailsSearch(search);
  const emailSearchResult = useEmailSearch(search);

  if (
    !emailSearchResult.isPending ||
    emailSearchResult.fetchStatus !== 'idle'
  ) {
    return Object.assign(emailSearchResult, { type: 'email' as const });
  }

  return Object.assign(deviceSearchResult, { type: 'device' as const });
}
