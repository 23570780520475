import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import StihlTooltip from '../../../../../base/stihl-material-ui/components/stihl-tooltip/stihl-tooltip';
import StihlIconInfo from '../../../../../base/stihl-material-ui/icons/stihl-icon-info';
import CardFieldContent from '../card-field-content/card-field-content';

type CardFieldOrganizationClaimInitiatorProps = {
  name: string;
  value: string;
};

const TypographyStyled = styled(Typography)`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
`;

const CardFieldOrganizationEmail: FC<
  CardFieldOrganizationClaimInitiatorProps
> = ({ name, value }) => {
  const { t } = useTranslation();

  return (
    <CardFieldContent name={name}>
      <TypographyStyled>
        {value}

        <StihlTooltip title={t('deviceDetails.organizationEmailTooltip')}>
          <StihlIconInfo />
        </StihlTooltip>
      </TypographyStyled>
    </CardFieldContent>
  );
};

export default CardFieldOrganizationEmail;
