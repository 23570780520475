import styled from '@emotion/styled';
import type { JSX } from 'react';

const ContentWrapper = styled.div`
  h3 {
    font-size: 1em;
  }
`;

const ReleaseNotesHeading = styled.div`
  h2 {
    margin: 0;
    font-size: 1em;
  }

  p {
    margin: 0;
  }
`;

const ReleaseNotesContent = (): JSX.Element => {
  return (
    <ContentWrapper>
      <ReleaseNotesHeading>
        <h2>IoT Service Portal 1.13.0</h2>
        <p>2024-12-03</p>
      </ReleaseNotesHeading>
      <h3>New Features</h3>
      <ul>
        <li>
          Integrate iMOW+ Device Model and Device Types ({}
          <strong>disabled for PROD</strong>)
        </li>
        <li>
          Display teached-in map data for iMOW+ ({}
          <strong>disabled for PROD</strong>)
        </li>
        <li>
          Disable &quot;Jump to App&quot; when mower isn&apos;t connected to a
          customer
        </li>
        <li>
          Prevent add mower to account if already fleet managed{' '}
          <strong>TBD Windhcill</strong>
        </li>
      </ul>
      <h3>General Maintenance</h3>
      <ul>
        <li>
          Consistent Fleet Management <strong>TBD Windchill...</strong>
        </li>
        <ul>
          <li>
            Identify who approved the fleet claiming (none, fleet manager,
            customer)
          </li>
          <li>Show fleet management status if not connected to a customer</li>
          <li>
            Show fleet management status if not approved by fleet manager or
            customer
          </li>
        </ul>
        <li>Accept all countries that are proved by STIHL ID</li>
        <li>
          Remove &quot;triggered by&quot; in Overall State of the Device Status
          Card
        </li>
        <li>
          Remove redundant fleet management info in Settings Card{' '}
          <strong>TBD Windchill</strong>
        </li>
      </ul>
      <h3>Bug Fixes</h3>
      <ul>
        <li>Device Search in Customer Management doesn&apos;t work for IA02</li>
        <li>OTA Update assignment for IA02 is not working</li>
        <li>
          Jump to app while another writing request is opened (in another role)
        </li>
        <li>Fix MY iMOW Backend health check</li>
      </ul>
    </ContentWrapper>
  );
};

export default ReleaseNotesContent;
