/* eslint-disable react/jsx-max-depth */
import styled from '@emotion/styled';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import type {
  JSX,
  Dispatch,
  ReactNode,
  RefObject,
  SetStateAction,
} from 'react';
import { useMemo, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StihlButtonSecondary from '../../../../base/stihl-material-ui/components/stihl-button/stihl-button-secondary';
import StihlTableHeader from '../../../../base/stihl-material-ui/components/stihl-table/stihl-table-header';
import type { Order } from '../../../../base/stihl-material-ui/components/stihl-table/table-utils';
import { getComparator } from '../../../../base/stihl-material-ui/components/stihl-table/table-utils';
import StihlTooltip from '../../../../base/stihl-material-ui/components/stihl-tooltip/stihl-tooltip';
import StihlIconDownload from '../../../../base/stihl-material-ui/icons/stihl-icon-download';
import { stihlColor } from '../../../../base/stihl-material-ui/theme/stihl-style-guide';
import { useAlertStore } from '../../../app-alert/service/alert-provider';
import Pagination from '../../../app-shell/ui/pagination/pagination';
import type { DeviceModel } from '../../../device/model';
import type {
  DeviceStatus,
  DeviceStatusColumns,
  DeviceStatusHeadCells,
} from '../../model/statistics.model';
import { DeviceStatusDisplayType } from '../../model/statistics.model';
import { useDeviceManagementStore } from '../../service/device-management-store/device-management-store-provider';
import DeviceManagementTableBody from './device-management-table/device-management-table-body';
import {
  DeviceManagementTableContext,
  DeviceStateActionType,
  initialValues,
  reducer,
} from './device-management-table/device-management-table.state';
import {
  createCsvFromErrorStatusData,
  createCsvFromStatusData,
  downloadCsv,
  // eslint-disable-next-line import/max-dependencies
} from './utils/csv-utils';

const StyledToolbar = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  border-block-end: 1px solid ${stihlColor.greyMid};
`;

const StyledToolbarTypography = styled(Typography)`
  block-size: 3rem;
  line-height: 3rem;
  text-align: center;
`;

const StyledNoDataCard = styled(Card)`
  min-block-size: 50vh;
  margin-block-start: 10px;
  text-align: center;
`;

const columns: (keyof DeviceStatusColumns)[] = [
  'serialNumber',
  'deviceId',
  'deviceModel',
  'deviceType',
  'softwareVersion',
  'isConnected',
  'processingChain',
  'errors',
];

const EnhancedTableToolbar = ({
  selectedDevicesNumber,
  displayType,
  softwareVersion,
  children,
}: {
  selectedDevicesNumber: number;
  displayType: DeviceStatusDisplayType;
  softwareVersion?: string | null;
  children: ReactNode;
}): JSX.Element => {
  const { t } = useTranslation();
  return (
    <StyledToolbar>
      {selectedDevicesNumber > 0 ? (
        <StyledToolbarTypography data-testid="selectedCount">
          {selectedDevicesNumber} {t('selected')}
        </StyledToolbarTypography>
      ) : (
        <StyledToolbarTypography>
          {displayType === DeviceStatusDisplayType.SoftwareVersion &&
            !!softwareVersion && (
              <b>
                {t('deviceStatusTable.swVersionDevices', { softwareVersion })}
              </b>
            )}
          {displayType === DeviceStatusDisplayType.ErrorDevices && (
            <b>{t('deviceStatusTable.errorDevices')}</b>
          )}
        </StyledToolbarTypography>
      )}
      {children}
    </StyledToolbar>
  );
};

type DeviceStatusTableHeaderProps = {
  order: 'asc' | 'desc';
  orderBy: Exclude<keyof DeviceStatusColumns, 'errors'>;
  setOrder: Dispatch<SetStateAction<Order>>;
  setOrderBy: Dispatch<
    SetStateAction<Exclude<keyof DeviceStatusColumns, 'errors'>>
  >;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  numberSelected: number;
  rowCount: number;
  shouldHaveCheckbox: boolean;
  displayType: DeviceStatusDisplayType;
};

const DeviceStatusTableHeader = ({
  order,
  orderBy,
  setOrder,
  setOrderBy,
  onSelectAllClick,
  numberSelected,
  rowCount,
  shouldHaveCheckbox,
  displayType,
}: DeviceStatusTableHeaderProps): JSX.Element => {
  const { t } = useTranslation();

  const headCells: DeviceStatusHeadCells[] = [
    {
      id: 'serialNumber',
      label: t('deviceStatusTable.serialNumber'),
      isSortable: true,
    },
    {
      id: 'deviceId',
      label: t('deviceStatusTable.deviceId'),
      isSortable: true,
    },
    {
      id: 'deviceModel',
      label: t('deviceStatusTable.deviceModel'),
      isSortable: true,
    },
    {
      id: 'deviceType',
      label: t('deviceStatusTable.deviceType'),
      isSortable: true,
    },
  ];

  if (displayType === DeviceStatusDisplayType.ErrorDevices) {
    headCells.push({
      id: 'errors',
      label: t('deviceStatusTable.errors'),
      isSortable: false,
    });
  }

  if (displayType === DeviceStatusDisplayType.SoftwareVersion) {
    headCells.push({
      id: 'softwareVersion',
      label: t('deviceStatusTable.swVersion'),
      isSortable: false,
    });
  }

  return (
    <StihlTableHeader<
      DeviceStatusColumns,
      Exclude<keyof DeviceStatusColumns, 'errors'>
    >
      order={order}
      orderBy={orderBy}
      setOrderBy={setOrderBy}
      setOrder={setOrder}
      onSelectAllClick={onSelectAllClick}
      numberSelected={numberSelected}
      rowCount={rowCount}
      headCells={headCells}
      shouldHaveCheckbox={shouldHaveCheckbox}
      shouldHaveLastColumnEmpty
    />
  );
};

export type DeviceStatusTableProps = {
  deviceModel: DeviceModel;
  deviceStatuses: DeviceStatus[];
  displayType: DeviceStatusDisplayType;
  tableRef: RefObject<HTMLDivElement>;
  isAllowedForUpdateManagement: boolean;
};

// eslint-disable-next-line max-lines-per-function
export const DeviceStatusTable = ({
  deviceModel,
  deviceStatuses,
  displayType,
  tableRef,
  isAllowedForUpdateManagement,
}: DeviceStatusTableProps): JSX.Element => {
  const { t } = useTranslation();

  const [deviceManagementState, dispatchDeviceManagementState] = useReducer(
    reducer,
    initialValues,
  );

  const contextProviderValue = useMemo(() => {
    return {
      state: deviceManagementState,
      dispatch: dispatchDeviceManagementState,
    };
  }, [deviceManagementState]);

  const [_, setAlert] = useAlertStore();

  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] =
    useState<Exclude<keyof DeviceStatusColumns, 'errors'>>('serialNumber');
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const devices = deviceStatuses ?? [];
  const [page, setPage] = useState(1);
  const [deviceManagementStore, setDeviceManagementStore] =
    useDeviceManagementStore();
  const [resultsPerPage, setResultsPerPage] = useState<number>(10);

  const firstToDisplay = (page - 1) * resultsPerPage;
  const lastToDisplay = (page - 1) * resultsPerPage + resultsPerPage;

  function handleSelectAllClick(
    event: React.ChangeEvent<HTMLInputElement>,
  ): void {
    const newSelectedDevices = devices.map((n) => n.deviceId);
    if (event.target.checked) {
      dispatchDeviceManagementState({
        type: DeviceStateActionType.SetUniqueSelectedDeviceIds,
        value: newSelectedDevices,
      });
      return;
    }

    dispatchDeviceManagementState({
      type: DeviceStateActionType.ResetUniqueSelectedDeviceIds,
    });
  }

  function addSelectedDevicesToStore(): void {
    const beforeNumberOfDevices =
      deviceManagementStore.multiAssignmentDevices.size;

    const selectedRows: DeviceStatus[] = devices.filter((row) =>
      deviceManagementState.uniqueSelectedDeviceIds.has(row.deviceId),
    );

    deviceManagementStore.addDevices(selectedRows);
    const numberOfDevicesAdded =
      deviceManagementStore.multiAssignmentDevices.size - beforeNumberOfDevices;

    const updatedStore = { ...deviceManagementStore };
    setDeviceManagementStore(updatedStore);

    const hasAddedSome =
      deviceManagementState.numberOfSelectedDevices > numberOfDevicesAdded;

    const hasAddedZero = numberOfDevicesAdded === 0;

    let alertMessage: string;

    if (hasAddedSome) {
      alertMessage = `${t('updateManagement.addedAlert', {
        count: numberOfDevicesAdded,
      })} ${t('updateManagement.previouslyAddedNumber', {
        count:
          deviceManagementState.numberOfSelectedDevices - numberOfDevicesAdded,
      })}`;
    } else if (hasAddedZero) {
      alertMessage = t('updateManagement.previouslyAdded', {
        count: deviceManagementState.numberOfSelectedDevices,
      });
    } else {
      alertMessage = `${t('updateManagement.addedAlert', {
        count: numberOfDevicesAdded,
      })}`;
    }
    setAlert({
      isOpen: true,
      message: alertMessage,
      severity: 'success',
    });

    dispatchDeviceManagementState({
      type: DeviceStateActionType.ResetUniqueSelectedDeviceIds,
    });
  }

  function downloadAsCsv(): void {
    const csvData =
      displayType === DeviceStatusDisplayType.ErrorDevices
        ? createCsvFromErrorStatusData(deviceStatuses)
        : createCsvFromStatusData(deviceStatuses);

    downloadCsv('devices-statuses-data', csvData);
  }

  if (deviceStatuses.length === 0) {
    return (
      <StyledNoDataCard data-testid="noDeviceStatuses">
        <Typography mt="1rem">{t('deviceStatusTable.noDevices')}</Typography>
      </StyledNoDataCard>
    );
  }

  const deviceModelInUpdateStack = [
    ...deviceManagementStore.multiAssignmentDevices,
  ][0]?.deviceModel;

  const isAddToUpdateStackDisabled =
    deviceManagementStore.multiAssignmentDevices.size > 0 &&
    deviceModel !== deviceModelInUpdateStack;
  return (
    <>
      <DeviceManagementTableContext.Provider value={contextProviderValue}>
        <Card
          ref={tableRef}
          data-testid="deviceStatuses"
          style={{ marginTop: '10px' }}
        >
          <EnhancedTableToolbar
            selectedDevicesNumber={
              deviceManagementState.numberOfSelectedDevices
            }
            displayType={displayType}
            softwareVersion={devices[0]?.softwareVersion}
          >
            {deviceManagementState.numberOfSelectedDevices > 0 &&
            isAllowedForUpdateManagement ? (
              <StihlTooltip
                title={t('updateManagement.updateStackDisabled', {
                  deviceModel: deviceModelInUpdateStack,
                })}
                placement="left"
                disableHoverListener={!isAddToUpdateStackDisabled}
              >
                <div>
                  <StihlButtonSecondary
                    onClick={addSelectedDevicesToStore}
                    data-testid="addDeviceButton"
                    disabled={isAddToUpdateStackDisabled}
                  >
                    {t('deviceStatusTable.addDevices')}
                  </StihlButtonSecondary>
                </div>
              </StihlTooltip>
            ) : (
              <StihlTooltip title={t('csv.deviceStatuses')} placement="left">
                <IconButton
                  onClick={downloadAsCsv}
                  data-testid="deviceStatusesDownloadButton"
                >
                  <StihlIconDownload color="black" />
                </IconButton>
              </StihlTooltip>
            )}
          </EnhancedTableToolbar>
          <TableContainer style={{ overflowY: 'hidden' }}>
            <Table data-testid="deviceStatusTable">
              <DeviceStatusTableHeader
                order={order}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
                setOrder={setOrder}
                onSelectAllClick={handleSelectAllClick}
                rowCount={deviceStatuses.length}
                numberSelected={deviceManagementState.numberOfSelectedDevices}
                shouldHaveCheckbox={isAllowedForUpdateManagement}
                displayType={displayType}
              />
              <DeviceManagementTableBody
                devicesForPage={[...devices]
                  .sort(getComparator(order, orderBy))
                  .slice(firstToDisplay, lastToDisplay)}
                displayColumns={columns}
                isAllowedForUpdateManagement={isAllowedForUpdateManagement}
                deviceStatusDisplayType={displayType}
              />
            </Table>
          </TableContainer>
        </Card>
      </DeviceManagementTableContext.Provider>
      {deviceStatuses.length > 0 && (
        <Pagination
          numberOfResults={deviceStatuses.length}
          page={page}
          setPage={setPage}
          itemToDisplay={t('customerManagement.deviceTable.devices')}
          resultsPerPage={resultsPerPage}
          setResultsPerPage={setResultsPerPage}
        />
      )}
    </>
  );
};

export default DeviceStatusTable;

/* eslint-enable react/jsx-max-depth */
