import type { ReactNode, SetStateAction, Dispatch, JSX } from 'react';
import { createContext, useContext, useMemo, useState } from 'react';
import type { DeviceManagementStore } from './device-management-store';
import createDeviceManagementStore from './device-management-store';

export type DeviceManagementStoreContextValue = [
  DeviceManagementStore,
  Dispatch<SetStateAction<DeviceManagementStore>>,
];

export const DeviceManagementStoreContext =
  createContext<DeviceManagementStoreContextValue | null>(null);

type Props = {
  children: ReactNode;
  initialStore?: DeviceManagementStore;
};

export const DeviceManagementStoreProvider = ({
  children,
  initialStore,
}: Props): JSX.Element => {
  const [store, setStore] = useState<DeviceManagementStore>(
    initialStore ?? createDeviceManagementStore(),
  );
  const value: DeviceManagementStoreContextValue = useMemo(
    () => [store, setStore],
    [store],
  );

  return (
    <DeviceManagementStoreContext.Provider value={value}>
      {children}
    </DeviceManagementStoreContext.Provider>
  );
};

export const useDeviceManagementStore =
  (): DeviceManagementStoreContextValue => {
    const store = useContext(DeviceManagementStoreContext);

    if (!store) {
      throw new Error(
        'useDeviceManagementStore must be used within a DeviceManagementStoreProvider.',
      );
    }

    return store;
  };
