import styled from '@emotion/styled';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiCard from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import type { AxiosError } from 'axios';
import type { JSX } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StihlButtonSecondary from '../../../../base/stihl-material-ui/components/stihl-button/stihl-button-secondary';
import StihlCircleValid from '../../../../base/stihl-material-ui/icons/stihl-icon-circle-valid';
import StihlNumberOne from '../../../../base/stihl-material-ui/icons/stihl-number-one';
import StihlNumberThree from '../../../../base/stihl-material-ui/icons/stihl-number-three';
import StihlNumberTwo from '../../../../base/stihl-material-ui/icons/stihl-number-two';
import { stihlColor } from '../../../../base/stihl-material-ui/theme/stihl-style-guide';
import { useAlertStore } from '../../../app-alert/service/alert-provider';
import { useStore } from '../../../app-authentication/service/authentication-store-provider';
import type { DeviceType, StihlDeviceTag } from '../../../device/model';
import { DeviceModel, AccessLicenses } from '../../../device/model';
import {
  startDryRunMassAssignmentUpdate,
  getSoftwareVersions,
} from '../../service/device-management-api-client/device-management-api-client';
import { useDeviceManagementStore } from '../../service/device-management-store/device-management-store-provider';
import MassAssignmentFilters from './mass-assignment/mass-assignment-filters';
import { MassAssignmentSummary } from './mass-assignment/mass-assignment-summary';
import MultiAssignmentDeviceTable from './multi-assignment/multi-assignment-device-table';
import MultiAssignmentSummary from './multi-assignment/multi-assignment-summary';
// eslint-disable-next-line import/max-dependencies
import SoftwareVersionAutocomplete from './software-version/software-version-auto-complete';

const StyledHorizontalLine = styled.hr`
  inline-size: 100%;
  margin: 0;
  border-block-start: 1px solid ${stihlColor.greyMid};
`;

const StyledEditTypography = styled(Typography)`
  font-size: 0.9rem;
  text-decoration: underline;
`;

const StyledMuiAccordionSummary = styled(MuiAccordionSummary)`
  flex-direction: row-reverse;

  /* This is the recommended way to style MUI components */
  /* stylelint-disable-next-line selector-combinator-disallowed-list */
  & .MuiAccordionSummary-expandIconWrapper {
    align-items: start;
    margin: 0;
  }

  /* stylelint-disable-next-line selector-combinator-disallowed-list */
  & .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(0deg);
  }

  /* stylelint-disable-next-line selector-combinator-disallowed-list */
  & .MuiAccordionSummary-content {
    align-items: center;
    justify-content: space-between;
    margin: 1rem 0;
    padding-inline-start: 1rem;
    padding-block-start: 0.25rem;
  }
`;

// eslint-disable-next-line max-lines-per-function
const UpdateManagement = (): JSX.Element => {
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState<string>('step1');
  const [availableSwVersions, setAvailableSwVersions] = useState<string[]>([]);
  const [selectedSwVersion, setSelectedSwVersion] = useState<string>('');

  const [deviceManagementStore] = useDeviceManagementStore();
  const [store] = useStore();

  const hasNoStoreDevices =
    deviceManagementStore.multiAssignmentDevices.size === 0;

  const initialDeviceModel = hasNoStoreDevices
    ? store.deviceModels[0]
    : [...deviceManagementStore.multiAssignmentDevices][0]?.deviceModel;

  const [deviceModel, setDeviceModel] =
    useState<DeviceModel>(initialDeviceModel);

  const [deviceTag, setDeviceTag] = useState<StihlDeviceTag | undefined>(
    undefined,
  );

  const [countryFilter, setCountryFilter] = useState('');
  const [swVersionFilter, setSwVersionFilter] = useState('');
  const [deviceTypes, setDeviceTypes] = useState<DeviceType[]>([]);

  const [shouldReset, setShouldReset] = useState(false);

  const [_, setAlert] = useAlertStore();

  const [isLoadingDryRun, setIsLoadingDryRun] = useState(false);
  const [affectedDevicesNumber, setAffectedDevicesNumber] = useState<
    number | string
  >('');

  const hasMassAssignmentAccess =
    store.deviceModels.includes(DeviceModel.connectedBox) ||
    store.license === AccessLicenses.ReleaseManagerLicense;

  function handleDeviceModel(model: DeviceModel): void {
    setDeviceModel(model);
  }

  function handleDeviceTag(tag: StihlDeviceTag | undefined): void {
    setDeviceTag(tag);
  }

  function handleCountryFilter(filterValue: string): void {
    setCountryFilter(filterValue);
  }
  function handleSoftwareVersion(filterValue: string): void {
    setSwVersionFilter(filterValue);
  }
  function handleDeviceType(filterValues: DeviceType[]): void {
    setDeviceTypes(filterValues);
  }

  function resetFilters(): void {
    setDeviceModel(store.deviceModels[0]);
    setDeviceTag(undefined);
    setCountryFilter('');
    setSwVersionFilter('');
    setDeviceTypes([]);
    setShouldReset(true);
  }

  useEffect(() => {
    getSoftwareVersions(deviceModel)
      .then((response) => {
        return setAvailableSwVersions(response);
      })
      .catch((error: Error) => {
        console.warn(error.message);
        setAvailableSwVersions([]);
      });
    return () => {
      setAvailableSwVersions([]);
    };
  }, [setAvailableSwVersions, deviceModel]);

  function handleStepChange(panel: string): void {
    if (panel === 'step1') {
      setShouldReset(false);
    }
    setExpanded(panel);
  }

  function handleOpenStepThree(): void {
    handleStepChange('step3');
    if (hasNoStoreDevices) {
      handleDryRun();
    }
  }

  function handleDryRun(): void {
    setIsLoadingDryRun(true);
    const country = countryFilter === '' ? undefined : countryFilter;
    const selectedDeviceTypes =
      deviceTypes.length > 0 ? deviceTypes : undefined;
    startDryRunMassAssignmentUpdate(
      deviceModel,
      swVersionFilter,
      selectedSwVersion,
      selectedDeviceTypes,
      country,
      deviceTag,
    )
      .then((response) => {
        setAffectedDevicesNumber(response);
        setIsLoadingDryRun(false);
        return true;
      })
      .catch((error: AxiosError) => {
        setAlert({
          isOpen: true,
          message: error.message,
          severity: 'error',
        });
        setAffectedDevicesNumber('?');
        return setIsLoadingDryRun(false);
      });
  }

  return (
    <MuiCard>
      <MuiAccordion
        expanded={expanded === 'step1'}
        onChange={() => handleStepChange('step1')}
        data-testid="panelStepOne"
        style={{ margin: '0', background: 'transparent', boxShadow: 'none' }}
      >
        <StyledMuiAccordionSummary
          expandIcon={
            expanded === 'step1' ? <StihlNumberOne /> : <StihlCircleValid />
          }
        >
          <Typography variant="h3">
            {t('updateManagement.selectDevices')}
          </Typography>
          <StyledEditTypography
            hidden={expanded === 'step1'}
            data-testid="editButtonStepOne"
          >
            {t('updateManagement.edit')}
          </StyledEditTypography>
        </StyledMuiAccordionSummary>
        <MuiAccordionDetails>
          {hasNoStoreDevices && hasMassAssignmentAccess ? (
            <MassAssignmentFilters
              swVersions={availableSwVersions}
              handleStepChange={handleStepChange}
              deviceModel={deviceModel}
              deviceModelCallback={handleDeviceModel}
              deviceTypeCallback={handleDeviceType}
              deviceTagCallback={handleDeviceTag}
              softwareVersionCallback={handleSoftwareVersion}
              countryFilterCallback={handleCountryFilter}
              disabled={!swVersionFilter}
              shouldReset={shouldReset}
            />
          ) : (
            <MultiAssignmentDeviceTable handleStepChange={handleStepChange} />
          )}
        </MuiAccordionDetails>
      </MuiAccordion>
      <StyledHorizontalLine />

      <MuiAccordion
        expanded={expanded === 'step2'}
        onChange={() => {
          if (expanded === 'step1') {
            return;
          }
          handleStepChange('step2');
        }}
        data-testid="panelStepTwo"
        // these styles are needed for Mui overwrite specificity reasons
        sx={{
          // eslint-disable-next-line @typescript-eslint/naming-convention
          '&::before': {
            blockSize: '0',
          },
        }}
        style={{ margin: '0', background: 'transparent', boxShadow: 'none' }}
      >
        <StyledMuiAccordionSummary
          expandIcon={
            expanded === 'step3' ? <StihlCircleValid /> : <StihlNumberTwo />
          }
        >
          <Typography variant="h3">
            {t('updateManagement.selectSoftwareVersions')}
          </Typography>
          <StyledEditTypography
            hidden={expanded !== 'step3'}
            data-testid="editButtonStepTwo"
          >
            {t('updateManagement.edit')}
          </StyledEditTypography>
        </StyledMuiAccordionSummary>
        <MuiAccordionDetails sx={{ inlineSize: '50%' }}>
          <SoftwareVersionAutocomplete
            availableSwVersions={availableSwVersions}
            setSelectedSwVersion={setSelectedSwVersion}
          />
          <br />
          <StihlButtonSecondary
            onClick={handleOpenStepThree}
            data-testid="continueButtonStepTwo"
            disabled={
              selectedSwVersion === '' ||
              !availableSwVersions.includes(selectedSwVersion)
            }
          >
            {t('updateManagement.continue')}
          </StihlButtonSecondary>
        </MuiAccordionDetails>
      </MuiAccordion>
      <StyledHorizontalLine />

      <MuiAccordion
        expanded={expanded === 'step3'}
        data-testid="panelStepThree"
        // these styles are needed for Mui overwrite specificity reasons
        sx={{
          // eslint-disable-next-line @typescript-eslint/naming-convention
          '&::before': {
            blockSize: '0',
          },
        }}
        style={{ margin: '0', background: 'transparent', boxShadow: 'none' }}
      >
        <StyledMuiAccordionSummary expandIcon={<StihlNumberThree />}>
          <Typography variant="h3">
            {t('updateManagement.runUpdate')}
          </Typography>
        </StyledMuiAccordionSummary>
        <MuiAccordionDetails sx={{ inlineSize: '50%' }}>
          <Typography sx={{ inlineSize: '500px' }}>
            {t('updateManagement.checkUpdate')}
          </Typography>
          {hasNoStoreDevices ? (
            <MassAssignmentSummary
              handleStepChange={handleStepChange}
              selectedSwVersion={selectedSwVersion}
              resetFilters={resetFilters}
              deviceModel={deviceModel}
              deviceTypes={deviceTypes}
              deviceTag={deviceTag}
              swVersionFilter={swVersionFilter}
              countryFilter={countryFilter}
              isLoadingDryRun={isLoadingDryRun}
              affectedDevicesNumber={affectedDevicesNumber}
            />
          ) : (
            <MultiAssignmentSummary
              handleStepChange={handleStepChange}
              selectedSwVersion={selectedSwVersion}
            />
          )}
        </MuiAccordionDetails>
      </MuiAccordion>
    </MuiCard>
  );
};

export default UpdateManagement;
