import styled from '@emotion/styled';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import type { JSX } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import StihlIconDeviceManagement from '../../../base/stihl-material-ui/icons/stihl-icon-device-management';
import StihlIconDevices from '../../../base/stihl-material-ui/icons/stihl-icon-devices';
import StihlIconQuestionmark from '../../../base/stihl-material-ui/icons/stihl-icon-questionmark';
import StihlIconSystemHealth from '../../../base/stihl-material-ui/icons/stihl-icon-system-health';
import StihlIconUserManagement from '../../../base/stihl-material-ui/icons/stihl-icon-user-management';
import { stihlColor } from '../../../base/stihl-material-ui/theme/stihl-style-guide';
import { useStore } from '../../app-authentication/service/authentication-store-provider';
import { NavigationItem } from '../model/app.models';

export const APPBAR_HEIGHT_IN_PX = 76;
export const TABBAR_HEIGHT_IN_PX = 48;
export const NAVIGATION_RAIL_SIZE_SPACING = 14.5;

const StyledSideBar = styled.div`
  min-inline-size: ${NAVIGATION_RAIL_SIZE_SPACING}rem;
  block-size: 100%;
  background-color: ${stihlColor.white};
  box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 8%);
`;

const StyledLink = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 8px 16px;
  color: ${stihlColor.black};
  text-transform: uppercase;
  text-decoration: none;

  &:hover {
    background-color: rgba(0, 0, 0, 8%);
  }

  &.active {
    .MuiTypography-body1 {
      font-weight: bold;
    }

    path {
      fill: ${stihlColor.stihlOrange};
    }
  }
`;

const StyledSidebarFooter = styled.div`
  position: absolute;
  min-inline-size: ${NAVIGATION_RAIL_SIZE_SPACING}rem;
  inset-block-end: 0;
  block-size: 2.25rem;
  margin-inline: 1rem;
  text-transform: none;
`;

const SideBar = (): JSX.Element => {
  const { t } = useTranslation();
  const [session] = useStore();

  return (
    <StyledSideBar data-testid="sidebar">
      <List>
        {session.navigationItems.includes(NavigationItem.Device) && (
          <StyledLink to="/device" data-testid="deviceNavigationItem">
            <ListItemIcon>
              <StihlIconDevices />
            </ListItemIcon>
            <ListItemText primary={t('navigation.device')} />
          </StyledLink>
        )}
        {session.navigationItems.includes(NavigationItem.DeviceManagement) && (
          <StyledLink
            to="/device-management"
            data-testid="deviceManagementNavigationItem"
          >
            <ListItemIcon>
              <StihlIconDeviceManagement />
            </ListItemIcon>
            <ListItemText primary={t('navigation.deviceManagement')} />
          </StyledLink>
        )}
        {session.navigationItems.includes(NavigationItem.CustomerManagment) && (
          <StyledLink
            to="/customer-management"
            data-testid="customerManagementNavigationItem"
          >
            <ListItemIcon>
              <StihlIconUserManagement />
            </ListItemIcon>
            <ListItemText primary={t('navigation.customerManagement')} />
          </StyledLink>
        )}
        {session.navigationItems.includes(
          NavigationItem.SystemHealthStatus,
        ) && (
          <StyledLink
            to="/system-health"
            activeClassName="active"
            data-testid="systemHealthNavigationItem"
          >
            <ListItemIcon>
              <StihlIconSystemHealth />
            </ListItemIcon>
            <ListItemText primary={t('navigation.systemHealth')} />
          </StyledLink>
        )}
        {session.navigationItems.includes(NavigationItem.Help) && (
          <StyledLink to="/help" activeClassName="active">
            <ListItemIcon>
              <StihlIconQuestionmark />
            </ListItemIcon>
            <ListItemText primary={t('navigation.help')} />
          </StyledLink>
        )}
      </List>
      <StyledSidebarFooter>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item xs={7}>
            <Typography variant="overline">
              {t('footer.version')} 1.13.0
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Typography variant="overline">{t('footer.date')}</Typography>
          </Grid>
        </Grid>
      </StyledSidebarFooter>
    </StyledSideBar>
  );
};

export default SideBar;
