import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import StihlTooltip from '../../../../../base/stihl-material-ui/components/stihl-tooltip/stihl-tooltip';
import StihlIconInfo from '../../../../../base/stihl-material-ui/icons/stihl-icon-info';
import CardFieldContent from '../card-field-content/card-field-content';

type CardFieldOrganizationClaimInitiatorProps = {
  name: string;
  value: 'Customer' | 'FleetManager' | null;
};

const TypographyStyled = styled(Typography)`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
`;

const CardFieldOrganizationClaimInitiator: FC<
  CardFieldOrganizationClaimInitiatorProps
> = ({ name, value }) => {
  const { t } = useTranslation();

  const { text, tooltip } =
    value === null
      ? {
          text: 'deviceDetails.organizationClaim.notInitiated',
          tooltip: 'deviceDetails.organizationClaim.notInitiatedTooltip',
        }
      : value === 'FleetManager'
        ? {
            text: 'deviceDetails.organizationClaim.initiatedByFleetManager',
            tooltip:
              'deviceDetails.organizationClaim.initiatedByFleetManagerTooltip',
          }
        : {
            text: 'deviceDetails.organizationClaim.initiatedByConsumer',
            tooltip:
              'deviceDetails.organizationClaim.initiatedByConsumerTooltip',
          };

  return (
    <CardFieldContent name={name}>
      <TypographyStyled>
        {t(text)}

        <StihlTooltip title={t(tooltip)}>
          <StihlIconInfo />
        </StihlTooltip>
      </TypographyStyled>
    </CardFieldContent>
  );
};

export default CardFieldOrganizationClaimInitiator;
