import Typography from '@mui/material/Typography';
import type { FC } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import StihlButtonSecondary from '../../../../../base/stihl-material-ui/components/stihl-button/stihl-button-secondary';
import CustomerRegistrationModal from '../../../../customer-management/ui/customer-registration/customer-registration-modal';

type CardFieldNoCustomerDetailsNoCustomerConnectedProps = {
  deviceSerialNumber: string;
  isFleetManaged: boolean;
};

const CardFiledCustomerDetailsNoCustomerConnected: FC<
  CardFieldNoCustomerDetailsNoCustomerConnectedProps
> = ({ deviceSerialNumber, isFleetManaged }) => {
  const { t } = useTranslation();
  const [isModalOpen, setModalOpen] = useState(false);

  function handleModalOpen(): void {
    setModalOpen(true);
  }

  return (
    <>
      {isFleetManaged ? (
        <Typography>{t('customerDetails.fleetManaged')}</Typography>
      ) : (
        <Typography>{t('customerDetails.noCustomer')}</Typography>
      )}
      <br />
      <StihlButtonSecondary disabled={isFleetManaged} onClick={handleModalOpen}>
        {t('customerDetails.connect')}
      </StihlButtonSecondary>

      <CustomerRegistrationModal
        open={isModalOpen}
        setOpen={setModalOpen}
        serialNumber={deviceSerialNumber}
      />
    </>
  );
};

export default CardFiledCustomerDetailsNoCustomerConnected;
