import styled from '@emotion/styled';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import type { FC, JSX } from 'react';
import { useCallback, useState, useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import StihlModal from '../../../../../base/stihl-material-ui/components/stihl-modal/stihl-modal';
import StihlTooltip from '../../../../../base/stihl-material-ui/components/stihl-tooltip/stihl-tooltip';
import StihlIconBoxLink from '../../../../../base/stihl-material-ui/icons/stihl-icon-box-link';
import StihlIconInvalid from '../../../../../base/stihl-material-ui/icons/stihl-icon-invalid';
import StihlIconMowerLink from '../../../../../base/stihl-material-ui/icons/stihl-icon-mower-link';
import { stihlColor } from '../../../../../base/stihl-material-ui/theme/stihl-style-guide';
import { useAlertStore } from '../../../../app-alert/service/alert-provider';
import { useStore } from '../../../../app-authentication/service/authentication-store-provider';
import { ActivationState } from '../../../../device-management/model/search.models';
import { useEnableOrDisableDeviceId } from '../../../../device-management/service/device-management-service/device-management-service';
import { AccessLicenses, DeviceModel } from '../../../model';
import type { DeviceResult } from '../../../model/card-field.model';
import { SearchParameterValues } from '../../../model/search.model';
// eslint-disable-next-line import/max-dependencies
import { DeviceTabsContext } from '../../tabs/device-tabs.context';

const StyledHeadingTableCell = styled(TableCell)`
  color: ${stihlColor.fontGrey};
  font-weight: bold;
  font-size: 0.875rem;
  text-decoration: none;
  border-block-end: none;
`;

const StyledBodyTableCell = styled(TableCell)`
  inline-size: 25%;
  font-size: 0.875rem;
  text-decoration: none;
  border-block-end: none;
`;

const StyledBodyTableCellPrimary = styled(StyledBodyTableCell)`
  font-weight: 600;
`;

const ActivationStateIconContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ChangeActivationState = ({
  device,
  onClick,
}: {
  device: DeviceResult;
  onClick: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    device: DeviceResult,
  ) => void;
}): JSX.Element => {
  const { t } = useTranslation();
  const isDisabled = device.iotDeviceActivationStatus === 'disabled';

  return (
    <StihlTooltip
      title={
        isDisabled
          ? t('searchResultTable.activationState.enableDeviceId')
          : t('searchResultTable.activationState.disableDeviceId')
      }
      placement="left"
    >
      <IconButton
        onClick={(event) => {
          onClick(event, device);
        }}
        data-testid={isDisabled ? 'enableDeviceId' : 'disableDeviceId'}
      >
        <StihlIconInvalid color={isDisabled ? 'error' : 'black'} />
      </IconButton>
    </StihlTooltip>
  );
};

const ActivationStateInfo = ({
  device,
}: {
  device: DeviceResult;
}): JSX.Element => {
  const { t } = useTranslation();
  const isDisabled = device.iotDeviceActivationStatus === 'disabled';

  return (
    <StihlTooltip
      title={
        isDisabled
          ? t('searchResultTable.activationState.deviceIdDisabled')
          : t('searchResultTable.activationState.deviceIdEnabled')
      }
      placement="left"
    >
      <ActivationStateIconContainer>
        <StihlIconInvalid
          color={isDisabled ? 'error' : undefined}
          data-testid={isDisabled ? 'deviceIdDisabled' : 'deviceIdEnabled'}
        />
      </ActivationStateIconContainer>
    </StihlTooltip>
  );
};

type CardFieldMultiDeviceSearchResultProps = {
  value: { results: DeviceResult[] };
};

const CardFieldMultiDeviceSearchResult: FC<
  CardFieldMultiDeviceSearchResultProps
  // eslint-disable-next-line max-lines-per-function
> = ({ value }) => {
  const { t } = useTranslation();
  const [_, setAlert] = useAlertStore();
  const [store] = useStore();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const history = useHistory();
  const [deviceInModal, setDeviceInModal] = useState<DeviceResult | undefined>(
    undefined,
  );
  const { openTab } = useContext(DeviceTabsContext);

  const enableOrDisableDeviceId = useEnableOrDisableDeviceId();

  const hasChangeActivationStateAccess =
    store.license === AccessLicenses.ReleaseManagerLicense ||
    store.license === AccessLicenses.SecondLevelSupportLicense ||
    store.license === AccessLicenses.DeveloperLicense;

  const hasActivationStateInfoAccess =
    store.license === AccessLicenses.FirstLevelSupportLicense ||
    store.license === AccessLicenses.VuLicense ||
    store.license === AccessLicenses.VuUsLicense ||
    store.license === AccessLicenses.DealerLicense ||
    store.license === AccessLicenses.DealerUsLicense;

  function handleOpenDevice(deviceId: string, deviceModel: DeviceModel): void {
    history.push('/device', {
      selector: SearchParameterValues.DeviceId,
      search: deviceId,
      deviceModel,
    });
  }

  function returnBoxOrImowLogo(device: DeviceResult): JSX.Element {
    return device.deviceModel === DeviceModel.connectedBox ? (
      <StihlIconBoxLink color="stihlOrange" />
    ) : (
      <StihlIconMowerLink
        color={
          device.iotDeviceActivationStatus === 'disabled'
            ? 'greyBase'
            : 'stihlOrange'
        }
      />
    );
  }

  const updateDeviceActivationState = useCallback(
    (updatedDevice: DeviceResult): void => {
      value.results = value.results.map((device) =>
        device.deviceId === updatedDevice.deviceId ? updatedDevice : device,
      );
    },
    [value],
  );

  const handleChangeActivationState = useCallback(() => {
    if (deviceInModal?.deviceId && deviceInModal.deviceModel != null) {
      const newActivationState =
        deviceInModal.iotDeviceActivationStatus === 'disabled'
          ? ActivationState.Enabled
          : ActivationState.Disabled;

      enableOrDisableDeviceId.mutate(
        {
          deviceId: deviceInModal.deviceId,
          deviceModel: deviceInModal.deviceModel,
          activationState: newActivationState,
        },
        {
          onSuccess: () => {
            updateDeviceActivationState({
              ...deviceInModal,
              iotDeviceActivationStatus: newActivationState,
            });
            setAlert({
              isOpen: true,
              message:
                newActivationState === ActivationState.Enabled
                  ? t('searchResultTable.activationState.enableDeviceIdSuccess')
                  : t(
                      'searchResultTable.activationState.disableDeviceIdSuccess',
                    ),
              severity: 'success',
            });
            setDeviceInModal(undefined);
            setIsModalOpen(false);
          },
          onError: (error) => {
            if (error.message.includes('409')) {
              setAlert({
                isOpen: true,
                message: t(
                  // eslint-disable-next-line no-secrets/no-secrets
                  'searchResultTable.activationState.enableDeviceIdConflictError',
                ),
                severity: 'error',
              });
            } else {
              setAlert({
                isOpen: true,
                message:
                  newActivationState === ActivationState.Enabled
                    ? t('searchResultTable.activationState.enableDeviceIdError')
                    : t(
                        'searchResultTable.activationState.disableDeviceIdError',
                      ),
                severity: 'error',
              });
            }
          },
        },
      );
    }
  }, [
    deviceInModal,
    enableOrDisableDeviceId,
    setAlert,
    t,
    updateDeviceActivationState,
  ]);

  function handleClose(): void {
    setDeviceInModal(undefined);
    setIsModalOpen(false);
  }

  function handleClick(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    device: DeviceResult,
  ): void {
    event.stopPropagation();
    setDeviceInModal(device);
    setIsModalOpen(true);
  }

  return (
    <>
      <Table sx={{ maxWidth: '50vw' }}>
        <TableHead>
          <TableRow>
            <StyledHeadingTableCell>
              {t('searchResultTable.headers.serialNumber')}
            </StyledHeadingTableCell>
            <StyledHeadingTableCell>
              {t('searchResultTable.headers.deviceId')}
            </StyledHeadingTableCell>
            <StyledHeadingTableCell>
              {t('searchResultTable.headers.deviceType')}
            </StyledHeadingTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {value.results.map((device) => (
            <TableRow
              key={device.deviceId}
              style={{ cursor: 'pointer' }}
              hover
              data-testid="tableRow"
              onClick={() => {
                if (value.results.length === 1) {
                  openTab('tabs.overview');
                } else if (device.deviceId && device.deviceModel != null) {
                  handleOpenDevice(device.deviceId, device.deviceModel);
                }
              }}
            >
              <StyledBodyTableCellPrimary>
                {device.serialNumber}
              </StyledBodyTableCellPrimary>
              <StyledBodyTableCell>{device.deviceId}</StyledBodyTableCell>
              <StyledBodyTableCell>{device.deviceType}</StyledBodyTableCell>
              <StyledBodyTableCell align="right">
                {returnBoxOrImowLogo(device)}
              </StyledBodyTableCell>
              <StyledBodyTableCell align="right">
                {hasChangeActivationStateAccess && (
                  <ChangeActivationState
                    device={device}
                    onClick={handleClick}
                  />
                )}
                {hasActivationStateInfoAccess &&
                  !hasChangeActivationStateAccess && (
                    <ActivationStateInfo device={device} />
                  )}
              </StyledBodyTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <StihlModal
        open={isModalOpen}
        onClose={handleClose}
        title={
          deviceInModal?.iotDeviceActivationStatus === 'enabled'
            ? t('searchResultTable.activationState.disableDeviceId')
            : t('searchResultTable.activationState.enableDeviceId')
        }
        actionButtonText={t('searchResultTable.activationState.save')}
        handleActionClick={handleChangeActivationState}
        data-testid="enableOrDisableModal"
        isLoading={enableOrDisableDeviceId.isPending}
      >
        <Typography>
          {deviceInModal?.iotDeviceActivationStatus === 'enabled' ? (
            <Trans
              i18nKey="searchResultTable.activationState.disableDeviceIdText"
              values={{ deviceId: deviceInModal.deviceId }}
              components={[<b key="0" />]}
            />
          ) : (
            <>
              <Trans
                i18nKey="searchResultTable.activationState.enableDeviceIdText"
                values={{ deviceId: deviceInModal?.deviceId }}
                components={[<b key="0" />]}
              />
              <div>
                {t('searchResultTable.activationState.enableDeviceIdHint')}
              </div>
            </>
          )}
        </Typography>
      </StihlModal>
    </>
  );
};

export default CardFieldMultiDeviceSearchResult;
