import { useMutation, useQuery } from '@tanstack/react-query';
import type { UseMutationResult, UseQueryResult } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import type { Connection } from '../../model/connection.model';
import type {
  CertificateStatus,
  DeviceModel,
  PermissionRequestResponse,
} from '../../model/device.model';
import type { EventLog } from '../../model/event-log.model';
import type { Page } from '../../model/page.model';
import type { SearchObject } from '../../model/search.model';
import { SearchParameterValues } from '../../model/search.model';
import {
  getCertificateStatus,
  getConnectionHistory,
  getDevice,
  getEventLog,
  renewDeviceCertificate,
  requestReadOnlyPermissions,
} from '../device-api-client/device-api-client';

export function useDeviceDetailsSearch(
  search: SearchObject | undefined,
): UseQueryResult<Page, Error> {
  return useQuery({
    // deps are exhaustive
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [
      'deviceSearch',
      search?.deviceModel,
      search?.selector,
      search?.search,
    ],
    queryFn: async ({ signal }) => getDevice(search, signal),
    refetchOnWindowFocus: false,
    enabled: search != null && search.selector !== SearchParameterValues.Email,
  });
}

export function useCertificateStatus(
  deviceId: string,
  deviceModel: DeviceModel,
): UseQueryResult<CertificateStatus, Error> {
  return useQuery<CertificateStatus, Error>({
    queryKey: ['certificateStatus', deviceId, deviceModel],
    queryFn: async ({ signal }) =>
      getCertificateStatus(deviceId, deviceModel, signal),
    enabled: false,
    refetchOnWindowFocus: false,
  });
}

export function useRenewDeviceCertificate(
  deviceId: string,
  deviceModel: string,
): UseMutationResult<void, AxiosError, void, unknown> {
  return useMutation({
    mutationFn: async () => renewDeviceCertificate(deviceId, deviceModel),
  });
}

export function useRequestReadOnlyPermissions(
  deviceId: string,
  deviceModel: string,
): UseMutationResult<PermissionRequestResponse, AxiosError, void, unknown> {
  return useMutation({
    mutationFn: async () => requestReadOnlyPermissions(deviceId, deviceModel),
  });
}

type UseConnectionHistoryOptions = {
  deviceId: string;
  deviceModel: DeviceModel;
  enabled?: boolean;
  from?: Date;
  to?: Date;
};

export function useConnectionHistory({
  deviceId,
  deviceModel,
  from,
  to,
  enabled = false,
}: UseConnectionHistoryOptions): UseQueryResult<Connection[], Error> {
  return useQuery<Connection[], Error>({
    queryKey: ['connectionHistory', deviceId, deviceModel, from, to],
    queryFn: async ({ signal }) =>
      getConnectionHistory({ deviceId, deviceModel, from, to, signal }),
    refetchOnWindowFocus: false,
    enabled,
  });
}

export function useEventLog(
  deviceId: string,
  deviceModel: string,
): UseQueryResult<EventLog, Error> {
  return useQuery<EventLog, Error>({
    queryKey: ['eventLog', deviceId, deviceModel],
    queryFn: async ({ signal }) => getEventLog(deviceId, deviceModel, signal),
    refetchOnWindowFocus: false,
    enabled: false,
  });
}
