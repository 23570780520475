import styled from '@emotion/styled';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import type { CommonProps } from '@mui/material/OverridableComponent';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import type { ReactElement, JSX } from 'react';
import { useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StihlButtonSecondary from '../../../../../base/stihl-material-ui/components/stihl-button/stihl-button-secondary';
import type { ChipType } from '../../../../../base/stihl-material-ui/components/stihl-chip-container/stihl-chip-container';
import { getComparator } from '../../../../../base/stihl-material-ui/components/stihl-table/table-utils';
import StihlTooltip from '../../../../../base/stihl-material-ui/components/stihl-tooltip/stihl-tooltip';
import StihlIconDownload from '../../../../../base/stihl-material-ui/icons/stihl-icon-download';
import { stihlColor } from '../../../../../base/stihl-material-ui/theme/stihl-style-guide';
import { useAlertStore } from '../../../../app-alert/service/alert-provider';
import Pagination from '../../../../app-shell/ui/pagination/pagination';
import type { DeviceModel } from '../../../../device/model';
import type {
  DeviceManagementColumns,
  ManagedDevice,
  ScreenSize,
} from '../../../model/overview.models';
import { useDeviceManagementStore } from '../../../service/device-management-store/device-management-store-provider';
import ManagedDeviceDetails from '../managed-device-details';
import { createCsvFromManagementData, downloadCsv } from '../utils/csv-utils';
import DeviceManagementTableBody from './device-management-table-body';
import DeviceManagementTableHeader from './device-management-table-header';
import {
  DeviceManagementTableContext,
  DeviceStateActionType,
  initialValues,
  reducer,
  // eslint-disable-next-line import/max-dependencies
} from './device-management-table.state';

const StyledToolbar = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  border-block-end: 1px solid ${stihlColor.greyMid};
`;

const StyledToolbarTypography = styled(Typography)`
  block-size: 3rem;
  line-height: 3rem;
  text-align: center;
`;

const StyledNoDataCard = styled(Card)`
  min-block-size: 50vh;
  margin-block-start: 10px;
  text-align: center;
`;

export const StyledTableContainer = styled(TableContainer)`
  display: flex;
  flex-direction: row;
  overflow-y: hidden;
`;

export const EnhancedTableToolbar = ({
  children,
  selectedDevicesNumber,
  title,
  shouldHaveCheckbox,
  ...rest
}: {
  children?: ReactElement<unknown>;
  selectedDevicesNumber: number;
  title: string;
  shouldHaveCheckbox?: boolean;
  rest?: CommonProps;
}): JSX.Element => {
  const { t } = useTranslation();
  return (
    <StyledToolbar {...rest}>
      {selectedDevicesNumber > 0 && shouldHaveCheckbox ? (
        <StyledToolbarTypography data-testid="selectedCount">
          {selectedDevicesNumber} {t('selected')}
        </StyledToolbarTypography>
      ) : (
        <StyledToolbarTypography>
          <b>{title}</b>
        </StyledToolbarTypography>
      )}
      {children}
    </StyledToolbar>
  );
};

function getDisplayColumns(
  isDetailsOpen: boolean,
  screenSize: ScreenSize,
): (keyof DeviceManagementColumns)[] {
  const minSet: (keyof DeviceManagementColumns)[] = [
    'serialNumber',
    'deviceId',
  ];
  const mediumSet: (keyof DeviceManagementColumns)[] = [
    ...minSet,
    'deviceModel',
    'deviceType',
  ];
  const maxSet: (keyof DeviceManagementColumns)[] = [
    ...mediumSet,
    'softwareVersion',
    'isConnected',
    'processingChain',
  ];

  if (isDetailsOpen) {
    switch (screenSize) {
      case 'small': {
        return minSet;
      }
      case 'medium': {
        return mediumSet;
      }
      case 'large': {
        return maxSet;
      }
      default: {
        return minSet;
      }
    }
  }
  switch (screenSize) {
    case 'small': {
      return mediumSet;
    }
    case 'medium':
    case 'large': {
      return maxSet;
    }
    default: {
      return mediumSet;
    }
  }
}

export type DeviceManagementTableProps = {
  error: Error | null;
  deviceManagementDevices?: ManagedDevice[];
  deviceModel: DeviceModel | undefined;
  chips: ChipType[];
  isAllowedForUpdateManagement: boolean;
};

// eslint-disable-next-line max-lines-per-function
const DeviceManagementTable = ({
  deviceManagementDevices,
  deviceModel,
  chips,
  isAllowedForUpdateManagement,
  error,
}: DeviceManagementTableProps): JSX.Element => {
  const { t } = useTranslation();

  const [deviceManagementState, dispatchDeviceManagementState] = useReducer(
    reducer,
    initialValues,
  );

  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] =
    useState<keyof DeviceManagementColumns>('serialNumber');

  const devices = deviceManagementDevices ?? [];

  const [page, setPage] = useState(1);
  const [resultsPerPage, setResultsPerPage] = useState<number>(10);

  const [deviceManagementStore, setDeviceManagementStore] =
    useDeviceManagementStore();

  const [_, setAlert] = useAlertStore();

  const hasSmallScreen = useMediaQuery('(max-width: 1024px)');
  const hasMediumScreen = useMediaQuery('(max-width: 1440px)');

  const screenSize = (): ScreenSize => {
    if (hasSmallScreen) {
      return 'small';
    }
    if (hasMediumScreen) {
      return 'medium';
    }
    return 'large';
  };

  function handleSelectAllClick(
    event: React.ChangeEvent<HTMLInputElement>,
  ): void {
    const newSelectedDevices = devices.map((n) => n.deviceId);
    if (event.target.checked) {
      dispatchDeviceManagementState({
        type: DeviceStateActionType.SetUniqueSelectedDeviceIds,
        value: newSelectedDevices,
      });
      return;
    }

    dispatchDeviceManagementState({
      type: DeviceStateActionType.ResetUniqueSelectedDeviceIds,
    });
  }

  function addSelectedDevicesToStore(): void {
    const selectedRows: ManagedDevice[] = devices.filter((row) =>
      deviceManagementState.uniqueSelectedDeviceIds.has(row.deviceId),
    );
    const numberOfDevicesBefore =
      deviceManagementStore.multiAssignmentDevices.size;

    deviceManagementStore.addDevices(selectedRows);
    const numberOfDevicesAdded =
      deviceManagementStore.multiAssignmentDevices.size - numberOfDevicesBefore;

    const updatedStore = { ...deviceManagementStore };
    setDeviceManagementStore(updatedStore);

    const hasAddedZero = numberOfDevicesAdded === 0;
    const hasAddedSome =
      deviceManagementState.numberOfSelectedDevices > numberOfDevicesAdded;
    let message: string;
    if (hasAddedZero) {
      message = t('updateManagement.previouslyAdded', {
        count: deviceManagementState.numberOfSelectedDevices,
      });
    } else if (hasAddedSome) {
      message = `${t('updateManagement.addedAlert', {
        count: numberOfDevicesAdded,
      })} ${t('updateManagement.previouslyAddedNumber', {
        count:
          deviceManagementState.numberOfSelectedDevices - numberOfDevicesAdded,
      })}`;
    } else {
      message = `${t('updateManagement.addedAlert', {
        count: numberOfDevicesAdded,
      })}`;
    }

    setAlert({
      isOpen: true,
      message,
      severity: 'success',
    });

    dispatchDeviceManagementState({
      type: DeviceStateActionType.ResetUniqueSelectedDeviceIds,
    });
  }

  const getSoftwareVersion = (deviceId: string): string => {
    return (
      devices.find((device) => device.deviceId === deviceId)?.softwareVersion ??
      ''
    );
  };
  const getConnectionState = (deviceId: string): boolean => {
    return (
      devices.find((device) => device.deviceId === deviceId)?.isConnected ??
      false
    );
  };

  function downloadAsCsv(): void {
    const csvData = createCsvFromManagementData(devices, chips);
    downloadCsv('device-management-data', csvData);
  }

  const firstToDisplay = (page - 1) * resultsPerPage;
  const lastToDisplay = (page - 1) * resultsPerPage + resultsPerPage;

  if (devices.length > 0) {
    const deviceModelInUpdateStack = [
      ...deviceManagementStore.multiAssignmentDevices,
    ][0]?.deviceModel;

    const isAddToUpdateStackDisabled =
      deviceManagementStore.multiAssignmentDevices.size > 0 &&
      deviceModel !== deviceModelInUpdateStack;

    return (
      <DeviceManagementTableContext.Provider
        value={{
          state: deviceManagementState,
          dispatch: dispatchDeviceManagementState,
        }}
      >
        <Card data-testid="deviceManagemendTable" style={{ marginTop: '10px' }}>
          <EnhancedTableToolbar
            selectedDevicesNumber={
              deviceManagementState.numberOfSelectedDevices
            }
            title={t('deviceManagementTable.devices')}
            shouldHaveCheckbox={isAllowedForUpdateManagement}
          >
            {deviceManagementState.numberOfSelectedDevices > 0 &&
            isAllowedForUpdateManagement ? (
              <StihlTooltip
                title={t('updateManagement.updateStackDisabled', {
                  deviceModel: deviceModelInUpdateStack,
                })}
                placement="left"
                disableHoverListener={!isAddToUpdateStackDisabled}
              >
                <div>
                  <StihlButtonSecondary
                    onClick={addSelectedDevicesToStore}
                    data-testid="addDeviceButton"
                    disabled={isAddToUpdateStackDisabled}
                  >
                    {t('deviceManagementTable.addDevices')}
                  </StihlButtonSecondary>
                </div>
              </StihlTooltip>
            ) : (
              <StihlTooltip title={t('csv.managementDevices')} placement="left">
                <IconButton
                  onClick={downloadAsCsv}
                  data-testid="deviceDataDownloadButton"
                >
                  <StihlIconDownload color="black" />
                </IconButton>
              </StihlTooltip>
            )}
          </EnhancedTableToolbar>
          <StyledTableContainer>
            <Table data-testid="deviceManagementTable">
              <DeviceManagementTableHeader
                order={order}
                orderBy={orderBy}
                setOrderBy={setOrderBy}
                setOrder={setOrder}
                onSelectAllClick={handleSelectAllClick}
                rowCount={devices.length}
                numberSelected={deviceManagementState.numberOfSelectedDevices}
                isDetailsOpen={deviceManagementState.isDetailsOpen}
                displayColumns={getDisplayColumns(
                  deviceManagementState.isDetailsOpen,
                  screenSize(),
                )}
                shouldHaveCheckbox={isAllowedForUpdateManagement}
              />
              <DeviceManagementTableBody
                devicesForPage={[...devices]
                  .sort(getComparator(order, orderBy))
                  .slice(firstToDisplay, lastToDisplay)}
                displayColumns={getDisplayColumns(
                  deviceManagementState.isDetailsOpen,
                  screenSize(),
                )}
                isAllowedForUpdateManagement={isAllowedForUpdateManagement}
              />
            </Table>
            {deviceManagementState.isDetailsOpen && (
              <ManagedDeviceDetails
                deviceModel={deviceModel}
                softwareVersion={getSoftwareVersion(
                  deviceManagementState.currentOpenDetailsId,
                )}
                isConnected={getConnectionState(
                  deviceManagementState.currentOpenDetailsId,
                )}
              />
            )}
          </StyledTableContainer>
        </Card>
        <Pagination
          numberOfResults={devices.length}
          page={page}
          setPage={setPage}
          itemToDisplay={t('deviceManagementTable.devices')}
          resultsPerPage={resultsPerPage}
          setResultsPerPage={setResultsPerPage}
        />
      </DeviceManagementTableContext.Provider>
    );
  }
  if (error?.message.includes('413')) {
    return (
      <StyledNoDataCard data-testid="tooManyDevices">
        <Typography mt="1rem">
          {t('deviceManagementTable.tooManyDevices')}
        </Typography>
      </StyledNoDataCard>
    );
  }
  if (error?.message.includes('406')) {
    return (
      <StyledNoDataCard data-testid="deviceTagNotAllowed">
        <Typography mt="1rem">
          {t('deviceManagementTable.notAllowed')}
        </Typography>
      </StyledNoDataCard>
    );
  }
  return (
    <StyledNoDataCard data-testid="noDeviceManagementDevices">
      <Typography mt="1rem">
        {t('deviceManagementTable.addFilterHint')}
      </Typography>
    </StyledNoDataCard>
  );
};

export default DeviceManagementTable;
