import type { FC } from 'react';
import type { CardFieldCustomerValue } from '../../../model/card-field.model';
import CardFieldCustomerDetailsCustomer from './card-field-customer-details-customer';
import CardFieldCustomerDetailsError from './card-field-customer-details-error';
import CardFiledCustomerDetailsNoCustomerConnected from './card-field-customer-details-no-customer-connected';

type CardFieldCustomerDetailsProps = {
  value: CardFieldCustomerValue;
};

const CardFieldCustomerDetails: FC<CardFieldCustomerDetailsProps> = ({
  value,
}) => {
  if (value.hasError) {
    return <CardFieldCustomerDetailsError />;
  }

  if (!value.hasCustomersAssigned) {
    return (
      <CardFiledCustomerDetailsNoCustomerConnected
        deviceSerialNumber={value.deviceSerialNumber}
        isFleetManaged={value.isFleetManaged}
      />
    );
  }

  return <CardFieldCustomerDetailsCustomer value={value} />;
};

export default CardFieldCustomerDetails;
