import styled from '@emotion/styled';
import type {
  ForwardRefExoticComponent,
  PropsWithoutRef,
  RefAttributes,
} from 'react';
import { forwardRef } from 'react';
import { getStihlThemeColor } from '../../theme/stihl-theme-colors';
import { useStihlTheme } from '../../theme/stihl-theme-provider';
import type { Svgr, SvgrProps } from './svgr';

export type StihlIconSvgrProps = SvgrProps & {
  color?: string;
  size?: number;
};

/**
 * Creates a `<StihlIconSvgr>` component for an icon processed via [SVGR]{@link https://react-svgr.com}.
 *
 * This applies styling, adds accessibility features and makes the icon Material UI themeable.
 * This way it behaves like Material UI's [<SvgIcon>]{@link https://material-ui.com/api/svg-icon} –
 * but with [SVGR]{@link https://react-svgr.com} – Material UI have their own way of handling icons
 * and does not use SVGR.
 *
 * @constructor
 */
export function createStihlIconSvgr(
  SvgrComponent: Svgr,
): ForwardRefExoticComponent<
  PropsWithoutRef<StihlIconSvgrProps> & RefAttributes<SVGSVGElement>
> {
  /**
   * @see https://github.com/mui-org/material-ui/blob/79e80738e5c67f2e80af4a5dbe56e6ae47fb62ad/packages/material-ui/src/SvgIcon/SvgIcon.js
   */
  const StyledSvgrComponent = styled(SvgrComponent)`
    display: inline-block;
    flex-shrink: 0;
  `;

  return forwardRef<SVGSVGElement, StihlIconSvgrProps>(
    ({ color = 'inherit', title, size, ...svgrProps }, ref) => {
      const theme = useStihlTheme();
      const colorFromTheme = getStihlThemeColor(theme, color);
      const dynamicProps: Partial<SvgrProps> = {};
      if (title) {
        dynamicProps.title = title;
      } else if (!svgrProps['aria-label']) {
        dynamicProps['aria-hidden'] = true;
      }
      if (size != null && size >= 0) {
        dynamicProps.width = size;
        dynamicProps.height = size;
      }
      return (
        <StyledSvgrComponent
          {...svgrProps}
          {...dynamicProps}
          focusable="false"
          color={colorFromTheme}
          fill="currentColor"
          ref={ref}
          style={{
            fontSize: theme.typography.pxToRem(24),
            transition: theme.transitions.create('fill', {
              duration: theme.transitions.duration.shorter,
            }),
          }}
        />
      );
    },
  );
}
