import type { Dispatch, FC, SetStateAction } from 'react';
import type { TabsType } from '../../../app-shell/model/app.models';
import HelpDtcCodes from '../../../help/ui/help-dtc-codes';
import DeviceSearchCard from '../../../search/ui/search-device-card';
import type { DiagnosisSessionData } from '../../model';
import type { Card as CardValue } from '../../model/card.model';
import { CardType } from '../../model/card.model';
import type { Meta } from '../../model/page.model';
import CertificateStatusCard from './certificate-status-card/certificate-status-card';
import ChangeEnvironmentCard from './change-environment-card/change-environment-card';
import CommandsCard from './commands-card/commands-card';
import ConnectionHistoryCard from './connection-history-card/connection-history-card';
import DemoModeCard from './demo-mode-card/demo-mode-card';
import DeviceStatusCard from './device-status-card/device-status-card';
import DiagnosisCard from './diagnosis-card/diagnosis-card';
import DiagnosisDetailsCard from './diagnosis-card/diagnosis-details/diagnosis-details-card';
import DirectHomeDriveCard from './direct-home-drive-card/direct-home-drive-card';
import EventLogCard from './event-log-card/event-log-card';
import FlagsCard from './flags-card/flags-card';
import GenericCard from './generic-card/generic-card';
import LogsCard from './logs-card/logs-card';
import MobileConnectionQualityCard from './mobile-connection-quality-card/mobile-connection-quality-card';
import OperationDataCard from './operation-data-card/operation-data-card';
import ProductionAndIdentificationDataCard from './production-data-card/production-data-card';
// eslint-disable-next-line import/max-dependencies
import SettingsCard from './settings-card/settings-card';

type CardProps = {
  card: CardValue;
  meta: Meta;
  isDataLoading: boolean;
  reloadData: () => void;
  dataUpdatedAt: number;
  openTab: (tab: TabsType) => void;
  diagnosisDataArray: DiagnosisSessionData[] | undefined;
  setDiagnosisDataArray: Dispatch<
    SetStateAction<DiagnosisSessionData[] | undefined>
  >;
};

const Card: FC<CardProps> = ({
  card,
  meta,
  isDataLoading,
  reloadData,
  dataUpdatedAt,
  openTab,
  diagnosisDataArray,
  setDiagnosisDataArray,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const isProduction = process.env.REACT_APP_ENV === 'prd';

  if (card.type === CardType.Search) {
    return <DeviceSearchCard isLoading={isDataLoading} />;
  }

  if (card.type === CardType.DeviceStatus) {
    return (
      <DeviceStatusCard
        meta={meta}
        cardData={card}
        lastUpdated={dataUpdatedAt}
        refreshData={reloadData}
        isLoading={isDataLoading}
      />
    );
  }

  if (card.type === CardType.Settings) {
    return <SettingsCard meta={meta} cardData={card} />;
  }

  if (card.type === CardType.Logs) {
    return <LogsCard meta={meta} cardData={card} />;
  }

  if (card.type === CardType.ChangeEnvironment) {
    return <ChangeEnvironmentCard meta={meta} cardData={card} />;
  }

  if (card.type === CardType.Flags) {
    return <FlagsCard meta={meta} cardData={card} />;
  }

  if (card.type === CardType.Commands) {
    return <CommandsCard meta={meta} cardData={card} />;
  }

  if (card.type === CardType.DemoMode) {
    return <DemoModeCard meta={meta} cardData={card} />;
  }

  if (card.type === CardType.OperationData) {
    return <OperationDataCard cardData={card} />;
  }

  if (card.type === CardType.DirectHomeDrive) {
    return isProduction ? (
      <div />
    ) : (
      <DirectHomeDriveCard meta={meta} cardData={card} />
    );
  }

  if (card.type === CardType.ProductionAndIdentificationData) {
    return <ProductionAndIdentificationDataCard meta={meta} cardData={card} />;
  }

  if (card.type === CardType.DiagnosisData) {
    return (
      <DiagnosisCard
        meta={meta}
        cardData={card}
        setVisibleTab={openTab}
        setDiagnosisData={setDiagnosisDataArray}
      />
    );
  }

  if (card.type === CardType.DiagnosisDetailsData) {
    return (
      <DiagnosisDetailsCard
        meta={meta}
        setVisibleTab={openTab}
        diagnosisDataArray={diagnosisDataArray}
        setDiagnosisData={setDiagnosisDataArray}
      />
    );
  }

  if (card.type === CardType.CertificateStatus) {
    return (
      <CertificateStatusCard
        deviceId={meta.deviceId}
        deviceModel={meta.deviceModel}
        cardData={card}
      />
    );
  }

  if (card.type === CardType.EventLog) {
    return (
      <EventLogCard
        deviceId={meta.deviceId}
        deviceModel={meta.deviceModel}
        cardData={card}
      />
    );
  }

  if (card.type === CardType.DtcCodes) {
    return <HelpDtcCodes codes={card.cardFields[0].value} isSearchBarHidden />;
  }

  if (card.type === CardType.QualityOfMobileConnection) {
    return <MobileConnectionQualityCard cardData={card} />;
  }

  if (card.type === CardType.ConnectionHistory) {
    return <ConnectionHistoryCard cardData={card} />;
  }

  return <GenericCard cardData={card} />;
};

export default Card;
