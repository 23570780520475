/* eslint-disable unicorn/no-thenable */
import styled from '@emotion/styled';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { useQueryClient } from '@tanstack/react-query';
import type { FormikHelpers } from 'formik';
import { useFormik } from 'formik';
import type { JSX } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { string, object } from 'yup';
import { StihlHelperText } from '../../../base/stihl-material-ui/components/stihl-helper-text/stihl-helper-text';
import StihlSelect from '../../../base/stihl-material-ui/components/stihl-select/stihl-select';
import StihlTextField from '../../../base/stihl-material-ui/components/stihl-text-field/stihl-text-field';
import StihlIconClose from '../../../base/stihl-material-ui/icons/stihl-icon-close';
import { useStore } from '../../app-authentication/service/authentication-store-provider';
import { AccessLicenses, DeviceModel } from '../../device/model/device.model';
import type { SearchObject } from '../../device/model/search.model';
import { SearchParameterValues } from '../../device/model/search.model';

export const SEARCHBAR_WIDTH_IN_PX = 380;

type SearchParameterObject = {
  value: SearchParameterValues;
  label: string;
};

const StyledForm = styled.form`
  display: flex;
  gap: 10px;
  /* Do not remove this- needed so that the form doesn't change its size when an error message is available */
  min-block-size: 110px;
  margin: 1.5rem 0;
`;

type SearchDeviceBarProps = {
  isLoading: boolean;
};

/* eslint-disable max-lines-per-function */
const SearchDeviceBar = ({ isLoading }: SearchDeviceBarProps): JSX.Element => {
  const [store] = useStore();
  const queryClient = useQueryClient();
  const [session] = useStore();
  const history = useHistory();

  const { t } = useTranslation();

  function handleSearch(
    searchValue: SearchObject,
    { setSubmitting }: FormikHelpers<SearchObject>,
  ): void {
    queryClient.removeQueries();

    history.push('/device', searchValue);
    setSubmitting(false);
  }

  function getSearchParameters(): SearchParameterObject[] {
    const parameters = [
      {
        value: SearchParameterValues.SerialNumber,
        label: t('searchParameters.serialNumberLabel'),
      },
    ];
    if (
      session.license !== AccessLicenses.DealerLicense &&
      session.license !== AccessLicenses.DealerUsLicense &&
      session.license !== AccessLicenses.ConnectedBoxSupportLicense &&
      session.license !== AccessLicenses.VuUsLicense
    ) {
      parameters.push(
        {
          value: SearchParameterValues.Imsi,
          label: t('searchParameters.imsiLabel'),
        },
        {
          value: SearchParameterValues.Imei,
          label: t('searchParameters.imeiLabel'),
        },
      );
    }
    if (values.deviceModel !== DeviceModel.connectedBox) {
      parameters.push({
        value: SearchParameterValues.Email,
        label: t('searchParameters.emailLabel'),
      });
    }
    if (
      values.deviceModel === DeviceModel.iMowIA01 ||
      values.deviceModel === DeviceModel.iMowIA01Plus ||
      values.deviceModel === DeviceModel.iMowIA02 ||
      values.deviceModel === DeviceModel.connectedBox
    ) {
      parameters.push({
        value: SearchParameterValues.DeviceId,
        label: t('searchParameters.deviceIdLabel'),
      });
    }

    if (
      values.deviceModel === DeviceModel.iMowIA01 ||
      values.deviceModel === DeviceModel.iMowIA01Plus
    ) {
      parameters.push({
        value: SearchParameterValues.FleetEmail,
        label: t('searchParameters.organizationEmail'),
      });
    }
    return parameters;
  }

  type OptionObject = {
    value: string;
    label: string;
  };

  const initialValues: SearchObject = {
    deviceModel: store.deviceModels[0],
    selector: SearchParameterValues.SerialNumber,
    search: '',
  };

  const validationSchema = object().shape({
    search: string()
      .when('selector', {
        is: SearchParameterValues.SerialNumber,
        then: (schema) =>
          schema
            .required()
            .matches(/^\d*$/u, t('formik.onlyNumbers'))
            .min(9, t('formik.min9Digits'))
            .max(10, t('formik.max10Digits')),
      })
      .when('selector', {
        is: SearchParameterValues.Email,
        then: (schema) =>
          schema
            .required()
            .matches(/^\S[^\s@]*@\S[^\s.]*\.\S+$/u, t('formik.validEmail'))
            .max(50, t('formik.max50Chars')),
      })
      .when('selector', {
        is: SearchParameterValues.FleetEmail,
        then: (schema) =>
          schema
            .required()
            .matches(/^\S[^\s@]*@\S[^\s.]*\.\S+$/u, t('formik.validEmail'))
            .max(50, t('formik.max50Chars')),
      })
      .when('selector', {
        is: SearchParameterValues.Imsi,
        then: (schema) =>
          schema
            .required()
            .matches(/^\d*$/u, t('formik.onlyNumbers'))
            .length(15, t('formik.exact15Digits')),
      })
      .when('selector', {
        is: SearchParameterValues.Imei,
        then: (schema) =>
          schema
            .required()
            .matches(/^\d*$/u, t('formik.onlyNumbers'))
            .length(15, t('formik.exact15Digits')),
      })
      .when('selector', {
        is: SearchParameterValues.DeviceId,
        then: (schema) =>
          schema
            .required()
            .matches(
              /^[\d_A-Z]+$/u,
              t('formik.onlyNumbersOrLettersOrUnderscore'),
            )
            .max(35, t('formik.max35Chars')),
      }),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSearch,
  });

  const {
    handleChange,
    handleSubmit,
    values,
    errors,
    touched,
    setFieldValue,
    resetForm,
  } = formik;

  useEffect(() => {
    if (!isLoading) {
      resetForm();
    }
  }, [isLoading, resetForm]);

  function getPlaceholderText(
    value: SearchParameterValues | undefined,
  ): string {
    switch (value) {
      case SearchParameterValues.Email: {
        return t('searchParameters.searchByEmail');
      }
      case SearchParameterValues.Imei: {
        return t('searchParameters.searchByImei');
      }
      case SearchParameterValues.Imsi: {
        return t('searchParameters.searchByImsi');
      }
      case SearchParameterValues.DeviceId: {
        return t('searchParameters.searchByDeviceId');
      }
      case SearchParameterValues.FleetEmail: {
        return t('searchParameters.searchByOrganizationEmail');
      }
      // eslint-disable-next-line unicorn/no-useless-switch-case
      case SearchParameterValues.SerialNumber:
      default: {
        return t('searchParameters.searchBySerialNumber');
      }
    }
  }

  function createDropDown(option: OptionObject): JSX.Element {
    return (
      <MenuItem
        key={option.value}
        value={`${t(option.value)}`}
        data-testid={`${t(option.value)}`}
      >
        {option.label}
      </MenuItem>
    );
  }

  function handleDeviceModelChange(
    event: React.ChangeEvent<HTMLInputElement>,
  ): void {
    void setFieldValue('selector', initialValues.selector);
    void setFieldValue('search', initialValues.search);
    void setFieldValue('deviceModel', event.target.value);
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>): void {
    void setFieldValue('search', event.target.value);
    void queryClient.cancelQueries({ queryKey: ['device'] });
    void queryClient.cancelQueries({ queryKey: ['emailSearch'] });
  }

  function handleSearchReset(): void {
    void setFieldValue('search', '');
    void queryClient.cancelQueries({ queryKey: ['device'] });
    void queryClient.cancelQueries({ queryKey: ['emailSearch'] });
  }

  return (
    <StyledForm onSubmit={handleSubmit} data-testid="searchform">
      <div>
        <InputLabel variant="standard" id="deviceModel">
          {t('deviceSearch.deviceModel')}
        </InputLabel>
        <StihlSelect
          SelectProps={{ labelId: 'deviceModel' }}
          name="deviceModel"
          value={values.deviceModel}
          sx={{ inlineSize: '250px' }}
          onChange={handleDeviceModelChange}
          data-testid="searchDeviceModel"
          disabled={
            values.selector === SearchParameterValues.Email ||
            values.selector === SearchParameterValues.FleetEmail
          }
        >
          {store.deviceModels.length > 0 ? (
            store.deviceModels.map((option) =>
              createDropDown({ value: option, label: option }),
            )
          ) : (
            <MenuItem disabled>{t('noDeviceModel')}</MenuItem>
          )}
        </StihlSelect>
      </div>
      <div>
        <InputLabel variant="standard" id="selectorLabel">
          {t('deviceSearch.searchCriteria')}
        </InputLabel>
        <StihlSelect
          SelectProps={{ labelId: 'selectorLabel' }}
          name="selector"
          value={values.selector}
          sx={{ inlineSize: '250px' }}
          onChange={handleChange('selector')}
          data-testid="searchSelector"
        >
          {getSearchParameters().map((option) => createDropDown(option))}
        </StihlSelect>
      </div>
      <StihlTextField
        id="search"
        inputProps={{
          'data-testid': 'searchBy',
          'aria-label': getPlaceholderText(values.selector),
        }}
        sx={{
          '&.MuiTextField-root.MuiFormControl-marginDense': {
            inlineSize: SEARCHBAR_WIDTH_IN_PX,
            minWidth: '70px',
            marginBlockStart: '28px', // DO NOT CHANGE THIS- NEEDED FOR ALIGNMENT
          },
        }}
        placeholder={getPlaceholderText(values.selector)}
        error={values.search !== initialValues.search && Boolean(errors.search)}
        helperText={
          values.search !== initialValues.search && errors.search ? (
            <StihlHelperText text={errors.search} />
          ) : (
            ''
          )
        }
        value={values.search}
        onChange={handleInputChange}
        name="search"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {values.search !== '' && (
                <IconButton
                  style={{ padding: '6px' }}
                  size="large"
                  onClick={handleSearchReset}
                  data-testid="resetButton"
                  aria-label={t('deviceSearch.resetButton')}
                >
                  <StihlIconClose color="text.primary" />
                </IconButton>
              )}
              <IconButton
                style={{ padding: '6px' }}
                type="submit"
                disabled={
                  touched.search &&
                  values.search !== '' &&
                  Boolean(errors.search)
                }
                size="large"
                aria-label={t('deviceSearch.searchButton')}
              >
                <SearchIcon color="secondary" />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </StyledForm>
  );
};

export default SearchDeviceBar;
/* eslint-enable max-lines-per-function */
/* eslint-enable unicorn/no-thenable */
